<template>
    <v-card class="ma-2 pa-0">

      <div class="ma-4">
        <!-- 机构标题 -->
        <v-layout align-center justify-right row wrap class="pa-4">
          <v-flex xs12 md12>
            <div class="title font-weight-bold">{{ organization.organization }}</div>
          </v-flex>
          <v-flex xs12 md12>
            <v-chip outlined small>
              <div class="subtitle-2" >{{ organization.membershipLevel }}</div>
            </v-chip>
          </v-flex>
        </v-layout>

        <!-- 所有者信息 -->
        <v-layout align-center justify-right row wrap class="py-0 px-4">
          <v-flex xs6 md4>
            <div class="caption grey--text">所有人</div>
            <div class="subtitle-2">{{ organization.ownerUserName }}</div>
          </v-flex>
          <v-flex xs6 md4>
            <div class="caption grey--text">电话</div>
            <div class="subtitle-2">{{ organization.ownerMobile }}</div>
          </v-flex>
          <v-flex xs12 md4>
            <div class="caption grey--text">邮箱</div>
            <div class="subtitle-2">{{ organization.ownerEmail }}</div>
          </v-flex>
        </v-layout>

        <!-- 助理信息 -->
        <v-layout align-center justify-right row wrap class="py-4 px-4">
          <v-flex xs6 md4>
            <div class="caption grey--text">管理助理</div>
            <div class="subtitle-2">{{ organization.manager }}</div>
          </v-flex>
          <v-flex xs6 md4>
            <div class="caption grey--text">培训助理</div>
            <div class="subtitle-2">{{ organization.trainer }}</div>
          </v-flex>
        </v-layout>
      </div>

      <!-- 详细信息导航按钮 -->
      <v-divider></v-divider>
      <v-flex class="d-flex justify-center" @click="showDetails = !showDetails">
        <v-btn text block small tile>
          <span class="caption grey--text">{{showDetails ? '收起' : '展开'}}详情</span>
          <v-icon>{{ showDetails ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
        </v-btn>
      </v-flex>

      <v-flex>
        <v-slide-y-transition>
          <v-card-text v-show="showDetails" class="grey--text">
            <div class="pt-0 px-0">
              <v-chip
                class='mr-2'
                :style="{ cursor: 'pointer'}"
                @click="$router.push({
                  name: 'classes',
                  query:{
                    organizationId: organization.organizationId,
                  }
                })"
                color="grey"
                text-color="white"
                small
              >
                班级
                <v-icon right small>info</v-icon>
              </v-chip>
              <v-chip
                class='mr-2'
                :style="{ cursor: 'pointer'}"
                @click="$router.push({
                  name: 'students',
                  query:{
                    organizationId: organization.organizationId,
                  }
                })"
                color="grey"
                text-color="white"
                small
              >
                学员
                <v-icon right small>info</v-icon>
              </v-chip>
              <v-chip
                class='mr-2'
                :style="{ cursor: 'pointer'}"
                @click="$router.push({
                  name: 'programs',
                  query:{
                    organizationId: organization.organizationId,
                  }
                })"
                color="grey"
                text-color="white"
                small
              >
                项目
                <v-icon right small>info</v-icon>
              </v-chip>
              <v-chip
                class='mr-2'
                :style="{ cursor: 'pointer'}"
                @click="$router.push({
                  name: 'lesson-templates',
                  query:{
                    organizationId: organization.organizationId,
                  }
                })"
                color="grey"
                text-color="white"
                small
              >
                结课
                <v-icon right small>info</v-icon>
              </v-chip>
              <v-chip
                class='mr-2'
                :style="{ cursor: 'pointer'}"
                @click="$router.push({
                  name: 'membership',
                  query:{
                    organizationId: organization.organizationId,
                  }
                })"
                color="grey"
                text-color="white"
                small
              >
                会员等级
                <v-icon right small>info</v-icon>
              </v-chip>
              <v-dialog v-model="calendarDialog" max-width="900px">
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="calendarDialog=true"
                    text
                    v-on="on"
                    color="info"
                  >
                    课程表
                    <v-icon>
                      calendar_month
                    </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="headline">{{organization.organization}} 课程表</span>
                  </v-card-title>

                  <v-card-text>
                    <y-lesson-calendar :organizationIds="[organization.organizationId]"/>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="warning"
                      @click="$router.push({
                        name: 'lesson-scheduler',
                        query: {
                          organizationId: organization.organizationId
                        }
                      })"
                    >新增排课
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="calendarDialog = false">关闭</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-card-text>
        </v-slide-y-transition>
      </v-flex>
    </v-card>
</template>

<script>
import YLessonCalendar from '../components/YLessonCalendar.vue'
export default {
  props: { // make it possible to pass in parameter <panel title="课程模版">
    organization: {
      type: Object,
      default: null
    }
  },
  components: {
    YLessonCalendar
  },
  data () {
    return {
      showDetails: false,
      calendarDialog: false
    }
  },
  computed: {
  },
  async mounted () {
  },
  filters: {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
