<template>
<div class="students">
  <h1 class="title grey--text">机构列表</h1>
  <v-container class="my-5">
    <div v-for="(organization) in organizationsInfo" v-bind:key="`org-${organization.organizationId}`">
      <y-organization :organization="organization"/>
    </div>
  </v-container>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import OrganizationService from '../services/OrganizationService'
import YOrganization from '../components/YOrganization.vue'

export default {
  components: {
    YOrganization
  },
  data () {
    return {
      organizationsInfo: []
    }
  },
  computed: {
  },
  async mounted () {
    console.log('\n#Page: Organization')
    this.$store.dispatch('setQueryingState', true)
    // 判断是否为manager
    if (this.$store.state.account.manager.id || this.$store.state.account.admin.id) {
      let managerIds = []
      if (this.$store.state.account.manager.id) managerIds = [this.$store.state.account.manager.id]
      if (this.$store.state.account.admin) managerIds.push(3)
      const res = await OrganizationService.getOrganizationsInfo({ managerIds })
      let organizationsInfo = res.data
      this.organizationsInfo = organizationsInfo
    } else {
      console.log('不具备经理账号, not a manager!')
    }
    this.$store.dispatch('setQueryingState', false)
  },
  methods: {
    async getStudentsByOrganizationIds (organizationIds) {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
